<template>
  <v-container id="partnership" fluid tag="section" class="container-full-size">
    <v-col cols="12">
      <v-btn color="default" class="mr-0" @click="back()">
        {{ str['back'] }}
      </v-btn>
    </v-col>

    <v-col cols="12">
      <div>
        <b>{{ str['partnership_name'] }}:</b>
        {{ partnership.name }}
      </div>
      <div>
        <b>{{ str['code'] }}:</b>
        {{ partnership.code }}
      </div>
      <div>
        <b>{{ str['partnership_percentage'] }}:</b>
        {{ partnership.percentage }}
      </div>
      <div>
        <b>{{ str['receive_only_commission_registrations'] }}:</b>
        {{ partnership.partnership_reg ? str['yes'] : str['no'] }}
      </div>
    </v-col>

    <v-tabs v-model="tabActive" background-color="transparent" color="secondary" style="margin-top: 0" grow show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.id">
        {{ tab.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabActive" style="min-height: calc(100% - 115px)">
      <div
        v-show="tabs[tabActive].id === 'payments'"
        style="position: absolute; top: 12px; right: 12px; max-width: 200px; height: 50px; z-index: 99; width: 100%"
      >
        <v-menu
          v-model="datepickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field v-model="datesText" :label="str['interval_dates']" readonly v-bind="attrs" hide-details v-on="on" />
          </template>
          <v-date-picker v-model="dates" range style="margin: 0" :locale="datepickerLanguage" @change="getPayments" />
        </v-menu>
      </div>

      <v-tab-item v-for="tab in tabs" :key="tab.id">
        <v-card v-if="tabs[tabActive].id === 'details'" color="basil" style="margin-top: 0" flat>
          <v-col cols="12">
            <v-row>
              <v-col v-for="item in detailsFields" :key="item.id" :cols="item.col ? item.col : 12">
                <div v-if="item.parent || item.col" style="height: 30px">
                  <span style="text-decoration: underline">
                    {{ item.parent ? item.parent : '' }}
                  </span>
                </div>

                <div>
                  <b>{{ item.title }}:</b>
                  <span>{{ item.value }}</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-card>

        <v-card v-if="tabs[tabActive].id === 'payments'" color="basil" style="margin-top: 0" flat>
          <div style="display: flex; justify-content: flex-end">
            <div style="width: 100%; height: 62px"></div>
          </div>
          <v-col cols="12">
            <div>
              <b>{{ str['received_value_€'] }}:</b>
              {{ total }}
            </div>
            <div>
              <b>{{ str['value_to_pay_partnership_€'] }}:</b>
              {{ partnershipTotal }}
            </div>
          </v-col>
          <v-data-table
            :headers="paymentsHeaders"
            :items="payments"
            :search="paymentsSearch"
            :no-data-text="str['payments_empty']"
            :no-results-text="str['payments_empty']"
            class="elevation-1"
          >
            <template #top>
              <v-toolbar flat>
                <v-text-field v-model="paymentsSearch" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
                <v-spacer />
              </v-toolbar>
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
              {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
            </template>
          </v-data-table>
        </v-card>

        <v-card v-if="tabs[tabActive].id === 'clients'" color="basil" style="margin-top: 0" flat>
          <v-data-table
            :headers="clientsHeaders"
            :items="clients"
            :search="clientsSearch"
            :no-data-text="str['clients_empty']"
            :no-results-text="str['clients_empty']"
            class="elevation-1"
            @click:row="openClient"
          >
            <template #top>
              <v-toolbar flat>
                <v-text-field v-model="clientsSearch" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
                <v-spacer />
              </v-toolbar>
            </template>
            <template #item.status="{ item }">
              {{ item.status === 1 ? str['suspended'] : item.status === 2 ? str['blocked'] : str['active'] }}
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
              {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  data() {
    const partnership = Utils.getStorage('partnership')
    const startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 1)

    return {
      str: window.strings,
      datepickerLanguage: window.datepickerLanguage,
      user: Utils.getUser(),
      partnership: partnership ? partnership : {},
      tabs: [
        {
          id: 'details',
          title: window.strings['details'],
        },
        {
          id: 'payments',
          title: window.strings['payments'],
        },
        {
          id: 'clients',
          title: window.strings['clients'],
        },
      ],
      tabActive: 0,
      total: 0,
      partnershipTotal: 0,
      dates: [Utils.getFormatDate(startDate), Utils.getFormatDate()],
      datepickerMenu: false,
      payments: [],
      paymentsSearch: '',
      paymentsHeaders: [
        { text: window.strings['client_id'], value: 'user_id', align: 'left' },
        { text: window.strings['date'], value: 'date', align: 'center' },
        { text: window.strings['value_€'], value: 'value', align: 'center' },
        {
          text: window.strings['partnership_value_€'],
          value: 'partnership_value',
          align: 'center',
        },
      ],
      clients: [],
      clientsSearch: '',
      clientsHeaders: [
        { text: window.strings['id'], value: 'id' },
        { text: window.strings['name'], value: 'name', align: 'center' },
        { text: window.strings['plan'], value: 'paym_info', align: 'center' },
        { text: window.strings['status'], value: 'status', align: 'center' },
        {
          text: window.strings['payment_date'],
          value: 'payday',
          align: 'center',
        },
        {
          text: window.strings['contact'],
          value: 'mobile_phone',
          align: 'center',
        },
      ],
      detailsFields: [],
    }
  },
  computed: {
    datesText() {
      return this.dates.join(' / ')
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.getDetailsFields()
    this.setDetails()
    this.getPayments()
    this.getClients()
  },
  methods: {
    back: function () {
      this.$router.goBack()
    },
    getDetailsFields: function () {
      const packages =
        this.user && this.user.configurations && this.user.configurations.partnerships ? this.user.configurations.partnerships.packages : []
      const fields = []

      for (let i = 0; i < packages.length; i++) {
        fields.push(
          {
            showParent: true,
            col: 3,
            id: 'package-value-' + i,
            title: window.strings['package_value_€'],
            value: '',
            number: true,
          },
          {
            col: 3,
            id: 'package-discount-' + i,
            title: window.strings['discount'],
            value: '',
            number: true,
          },
          {
            col: 3,
            id: 'package-sale_value-' + i,
            title: window.strings['sale_value_€'],
            value: '',
            number: true,
          },
          {
            col: 3,
            id: 'package-partnership_value-' + i,
            title: window.strings['partnership_value_€'],
            value: '',
            number: true,
          },
        )
      }

      this.detailsFields = fields
    },
    setDetails: function () {
      const packages = JSON.parse(this.partnership.packages)

      for (let i = 0; i < this.detailsFields.length; i++) {
        if (packages && this.detailsFields[i].id.indexOf('package') > -1) {
          const idSplit = this.detailsFields[i].id.split('-')
          const key = idSplit[1]
          const index = idSplit[2]

          if (this.detailsFields[i].number) {
            this.detailsFields[i].value = Math.round(packages[index][key] * 100) / 100
          } else {
            this.detailsFields[i].value = packages[index][key]
          }

          if (this.detailsFields[i].showParent) {
            this.detailsFields[i].parent = packages[index].name + ' (' + this.getTypeText(packages[index].type) + ')'
          }
        } else {
          if (this.detailsFields[i].number) {
            this.detailsFields[i].value = Math.round(this.partnership[this.detailsFields[i].id] * 100) / 100
          } else {
            this.detailsFields[i].value = this.partnership[this.detailsFields[i].id]
          }
        }
      }
    },
    getTypeText(type) {
      const promoCodePlanType = Utils.getPromoCodesPlanTypes().find(function (item) {
        return item.value === type
      })
      if (promoCodePlanType) {
        return promoCodePlanType.label
      } else {
        return ''
      }
    },
    getPayments: function () {
      const self = this
      const payload = {
        startDate: this.dates[0],
        endDate: this.dates[1],
        code: this.partnership.code,
      }

      this.$isLoading(true)

      Api.getPartnershipPayments(payload, function (response) {
        self.$isLoading(false)
        if (response.success) {
          self.payments = response.data
            ? response.data.sort(function (a, b) {
                const keyA = new Date(a.date)
                const keyB = new Date(b.date)
                if (keyA < keyB) return -1
                if (keyA > keyB) return 1
                return 0
              })
            : []

          self.total = 0
          self.partnershipTotal = 0

          for (let i = 0; i < self.payments.length; i++) {
            self.total += self.payments[i].value
            self.partnershipTotal += self.payments[i].partnership_value
          }
        } else {
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    getClients: function () {
      const self = this
      Api.getPartnershipClients(this.partnership.code, function (response) {
        if (response.success) {
          self.clients = response.data
        } else {
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    openClient: function (client) {
      Utils.setStorage('client', client)
      window.clientPageCacheList = null
      this.$router.navigate.push({
        path: '/home/client',
        query: { tab: 'details' },
      })
    },
  },
}
</script>
